@import "shared/assets/styles";

.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
}

.checkbox {
  --size: 16px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  input {
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    border: 2px solid $gray-30;
    background-color: transparent;
    transition:
      background-color 0.3s,
      border-color 0.3s;

    &:hover {
      border-color: $gray-40;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: $white;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.checked {
    input {
      background-color: $orange-yellow;
      border-color: $orange-yellow;

      &:hover {
        background-color: $orange-yellow-hover;
        border-color: $orange-yellow-hover;
      }
    }

    .icon {
      opacity: 1;
      width: 16px;
      height: 16px;
    }
  }

  &-group {
    display: flex;
    gap: 12px;
  }
}

.label {
  margin-left: 8px;
  user-select: none;
  color: $gray-80;
  transition: color 0.3s;

  @include font-lg(400);
}

/* stylelint-disable no-descending-specificity */
.disabled {
  pointer-events: none;

  input {
    border-color: $gray-10;
  }

  .checked {
    input {
      background-color: $orange-yellow-disabled;
      border-color: $orange-yellow-disabled;
    }
  }

  .label {
    color: $gray-40;
  }
}
