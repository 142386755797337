@import "shared/assets/styles";

.container {
  position: relative;

  &-absolute {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.gray {
  color: $gray-30;
}

.yellow {
  color: $orange-yellow;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
}

.icon {
  display: block;
  width: var(--size);
  height: var(--size);
  fill: none !important;
  stroke: currentcolor;
  animation: icon-rotate 2s infinite linear;

  &-small {
    --size: 24px;
  }

  &-normal {
    --size: 42px;
  }

  &-large {
    --size: 56px;
  }
}

@keyframes icon-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.dash {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 3;
  stroke-linecap: round;
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

.text {
  @include font-lg(400);

  line-height: 100%;
  text-align: center;
}

.icon + .text {
  margin-top: 12px;
}

.dots {
  display: inline-flex;
  justify-content: flex-start;

  .dot {
    display: inline-block;
    animation: var(--animation-name) 4s infinite;
  }

  .dot:first-child {
    --animation-name: dot-fade-first;
  }

  .dot:nth-child(2) {
    --animation-name: dot-fade-second;
  }

  .dot:last-child {
    --animation-name: dot-fade-last;
  }
}

@keyframes dot-fade-first {
  0%,
  10%,
  100% {
    opacity: 0;
  }

  30%,
  90% {
    opacity: 1;
  }
}

@keyframes dot-fade-second {
  0%,
  30%,
  100% {
    opacity: 0;
  }

  50%,
  90% {
    opacity: 1;
  }
}

@keyframes dot-fade-last {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  70%,
  90% {
    opacity: 1;
  }
}

.fade {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
