@import "shared/assets/styles";

.footer {
  position: relative;
  width: 100%;
  padding: 24px 0;
  background-color: $black;
}

.content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    gap: 24px;
    justify-content: center;
  }
}

.left {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 24px;
  position: relative;

  .logo {
    padding-top: 4px;
  }

  .help {
    text-align: right;

    .count {
      @include font-sm(700);

      background-color: $red;
      padding: 2px 4px;
      border-radius: 8px;
      line-height: 100%;
      color: $white;
    }
  }

  @include tablet {
    width: 100%;
    gap: initial;
    justify-content: space-between;
  }
}

.info {
  svg {
    fill: currentcolor;
  }
}
