@import "shared/assets/styles";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 6px;
  padding-top: 30px;
}

.code {
  font-size: 120px;
  font-weight: 900;
  line-height: 100%;
  color: $gray-20;
}

.text {
  text-align: center;
  margin-bottom: 18px;
}

.button {
  span {
    font-size: 14px;
  }
}
