@import "shared/assets/styles";

.right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 1;
  height: 100%;

  .profile {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  .account {
    fill: $orange-yellow;
  }

  .name {
    color: $orange-yellow;
    margin-right: 12px;
    max-width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    flex-shrink: 1;

    @include font-lg(700);

    @include laptop {
      max-width: 180px;
    }

    @include tablet {
      display: none;
    }
  }

  .balance {
    display: flex;
    gap: 20px;
    color: $white;
    margin-right: 6px;

    @include font-lg(700);

    line-height: 100%;

    @include laptop {
      display: none;
    }
  }

  .info-icon {
    fill: $gray-40;
    width: 24px;
    height: 24px;
  }

  @include laptop {
    max-width: 22%;
  }
}

.drawer {
  display: none;

  @include tablet {
    display: block;
  }
}

.profile-info {
  position: relative;
  padding-top: 20px;

  .text {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    color: $white;
    text-align: left;

    &-with-arrow {
      justify-content: space-between;
    }

    .name {
      display: flex;
      align-items: center;
      gap: 6px;

      .profile {
        fill: $orange-yellow;
        width: 20px;
        height: 20px;
      }
    }

    .arrow {
      fill: $orange-yellow;
      width: 24px;
      height: 24px;
    }
  }

  .link {
    color: $orange-yellow;
    gap: 6px;
    text-decoration: none;

    @include font-xl(700);
  }
}
