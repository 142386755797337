@import "shared/assets/styles";

.tooltip {
  display: none;
  border-radius: 0%;
  background-color: $black;
  transition: 0.3s;
  width: max-content;
  position: absolute;
  top: 50px;
  right: -26px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;

  &:hover {
    .tooltip {
      display: block;
    }
  }

  @include tablet {
    display: none;
  }
}

.target {
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}
