@import "./shared/assets/styles";
@include font-family;

$scroll-width: 8px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $gray-20 transparent;
}

*::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-width;
  padding: 0 calc($scroll-width / 2);
  border-radius: calc($scroll-width / 2);
  overflow: hidden;
}

*::-webkit-scrollbar-track {
  background: $gray-5;
}

*::-webkit-scrollbar-thumb {
  width: $scroll-width;
  height: $scroll-width;
  background: $gray-20;
  border-radius: calc($scroll-width / 2);
  border: none;
}

html,
body {
  height: auto;
  min-height: 100%;
  width: 100%;
  font-family:
    Roboto,
    Tahoma,
    Arial,
    sans-serif,
    -apple-system;
  scroll-behavior: smooth;
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-color: $white;
  color: $black;
}

pre {
  font-family:
    Roboto,
    Tahoma,
    Arial,
    sans-serif,
    -apple-system;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

input {
  font-family: unset;
}

input[type="number"] {
  appearance: textfield;
}

table {
  border-spacing: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 74px;
  min-height: 100vh;
  position: relative;

  @include small-tablet {
    padding-top: 66px;
  }
}

.container {
  padding-bottom: 100px;
}
