@import "shared/assets/styles";

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 20px;

  .control {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 4px;

    .button {
      padding: 0;
      background-color: transparent;
      border: none;

      .icon {
        width: 24px;
        height: 24px;
        transition: transform 0.3s;
        fill: $black;

        &.rotate {
          transform: rotateZ(180deg);
        }

        &.close {
          fill: $gray-80;
        }
      }
    }
  }

  &.preview {
    gap: 30px;
  }
}

.checkbox {
  margin-top: 34px;
}

.text-with-offset {
  margin-bottom: 50px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
