@import "shared/assets/styles";

.burger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    &.close {
      fill: $white;
    }
  }
}
