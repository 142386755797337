@import "shared/assets/styles";

.navigation {
  display: flex;
  gap: 24px;
  align-items: stretch;
  height: 100%;

  @include tablet {
    gap: 8px;
  }

  @include small-tablet {
    display: none;

    &.mobile {
      display: block;
      height: fit-content;
      padding-top: 20px;
      padding-bottom: 100px;
    }
  }
}
