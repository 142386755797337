@import "shared/assets/styles";

.text {
  padding: 13px 26px 13px 24px;
  position: relative;
  color: $white;
  display: flex;
  gap: 4px;
  line-height: 100%;

  &:first-child {
    display: none;

    @include laptop {
      display: flex;
    }
  }
}
