@import "shared/assets/styles";

.status {
  display: inline-block;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: var(--status-color-bg);
  color: $black;
  max-width: fit-content;

  @include font-md(400);

  line-height: 100%;

  &.indicator {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    &::before {
      --size: 8px;

      content: "";
      display: block;
      width: var(--size);
      height: var(--size);
      flex-shrink: 0;
      border-radius: 50%;
      background-color: var(--status-color-bg-indicator);
    }
  }

  &.red {
    --status-color-bg: #{$red-10};
    --status-color-bg-indicator: #{$red};
  }

  &.orange {
    --status-color-bg: #{$orange-yellow-10};
    --status-color-bg-indicator: #{$orange-yellow};
  }

  &.dark-orange {
    --status-color-bg: #{$orange-10};
    --status-color-bg-indicator: #{$orange};
  }

  &.yellow {
    --status-color-bg: #{$yellow-10};
    --status-color-bg-indicator: #{$yellow};
  }

  &.green {
    --status-color-bg: #{$green-10};
    --status-color-bg-indicator: #{$green};
  }

  &.blue {
    --status-color-bg: #{$blue-10};
    --status-color-bg-indicator: #{$blue};
  }

  &.dark-blue {
    --status-color-bg: #{$dark-blue-10};
    --status-color-bg-indicator: #{$dark-blue};
  }

  &.purple {
    --status-color-bg: #{$purple-10};
    --status-color-bg-indicator: #{$purple};
  }

  &.pink {
    --status-color-bg: #{$pink-10};
    --status-color-bg-indicator: #{$pink};
  }

  &.gray {
    --status-color-bg: #{$gray-10};
    --status-color-bg-indicator: #{$gray-60};
  }

  .text {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    // &.indicator {
    //   max-width: calc(100% - 12px);
    // }

    &.wrap {
      white-space: normal;
      max-width: none;
      overflow: unset;
      text-overflow: unset;
    }
  }
}
