@import "shared/assets/styles";

.skeleton {
  $column: 100% / 12;

  display: block;
  width: calc(var(--skeleton-columns) * #{$column});
  height: calc(var(--skeleton-rows) * 12px);
  border-radius: 8px;
  background-color: $gray-10;

  &.active {
    position: relative;
    background-color: $gray-5;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 240px;
      height: 100%;
      background-image: linear-gradient(90deg, $gray-5 0%, $gray-10 50%, $gray-5 100%);
      animation: skeleton-load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  &.right {
    margin-right: 0;
    margin-left: auto;
  }

  &.center {
    margin-right: auto;
    margin-left: auto;
  }

  + .skeleton {
    margin-top: 12px;
  }

  @keyframes skeleton-load {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }
}
