@import "shared/assets/styles";

.link {
  position: absolute;
}

.background {
  position: fixed;
  z-index: 0;
  top: 50px;
  object-fit: cover;
  margin: 0 auto;
  object-position: top center;
  left: 50%;
  transform: translate(-50%, 0);

  @include phone {
    height: 60px;
  }
}
