@import "shared/assets/styles";

.left {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;

  .burger {
    display: none;

    @include small-tablet {
      display: flex;
      margin-right: 12px;
    }
  }

  .menu {
    fill: $white;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 24px;

    img {
      height: auto;
    }

    @include small-tablet {
      margin-right: 0;
    }
  }

  @include small-tablet {
    align-items: center;
  }
}

.drawer {
  display: none;

  @include small-tablet {
    display: block;
  }
}
