@import "shared/assets/styles";

.container {
  width: 100%;

  a {
    display: none !important;
  }

  img {
    display: none !important;
  }

  .text {
    @include font-sm(700);
  }

  .content {
    max-height: 36px;
    overflow: hidden;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    @include font-lg(400);
  }

  &.preview {
    .text {
      @include font-sm(400);
    }

    .content {
      color: $gray-80;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 8px;

    .tag {
      @include font-md(400);

      color: $gray-80;
      line-height: 100%;
    }

    .date {
      color: $gray-80;
    }
  }

  .title {
    margin-bottom: 10px;
  }
}
