@import "shared/assets/styles";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 50px;
  background-color: $black;

  .container {
    height: 100%;
    max-width: 1320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 20px;

    @include desktop {
      max-width: 100%;
    }

    @include phone {
      gap: 0;
    }
  }
}
