@import "shared/assets/styles";

.lock {
  overflow: hidden;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 100;
  background-color: rgba($black, 25%);
}

.dialog {
  max-width: 100%;
  margin: auto;
}

.content {
  margin-top: 72px;
  margin-bottom: 72px;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 12px;
  padding: 16px 24px;
  border-bottom: 1px solid $gray-10;

  .close {
    margin-left: auto;
    padding: 3px;
    border: none;
    flex-shrink: 0;

    &:hover {
      border: none;
      background-color: transparent;
    }

    span {
      width: 24px;
      height: 24px;
    }

    &-icon {
      fill: $gray-40;
      width: 24px;
      height: 24px;
    }
  }
}

.title {
  @include font-xl(700);

  line-height: 150%;
}

.body {
  padding: 24px;

  @include font-lg(400);
}

.no-padding {
  padding: 0;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0 12px;
  padding: 16px 24px;
  border-top: 1px solid $gray-10;
}

.fade {
  &-enter {
    opacity: 0;

    .dialog {
      transform: translateY(-50%);
    }
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.4s;

    .dialog {
      transform: translateY(0);
      transition: transform 0.4s;
    }
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.4s;

    .dialog {
      transform: translateY(-50%);
      transition: transform 0.4s;
    }
  }
}
