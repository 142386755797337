@import "shared/assets/styles";

.container {
  --size: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-right: 12px;

  @include tablet {
    margin-right: 16px;
  }

  .bell {
    --size: 24px;

    position: relative;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    z-index: 10;
    padding: 0;
    color: $white;

    &-icon {
      fill: $white;
      width: var(--size);
      height: var(--size);
      transition: fill 0.3s;

      &::after {
        width: 8px;
        height: 8px;
      }
    }

    &.open {
      svg {
        fill: $orange-yellow;
      }
    }

    &:hover {
      svg {
        fill: $orange-yellow;
      }
    }

    .label {
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $red;
    }
  }

  .content {
    position: absolute;
    display: flex;
    top: 50px;
    right: 0;
    flex-direction: column;
    background-color: $white;
    text-align: start;
    z-index: 0;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
    width: 540px;
    max-width: 542px;
    max-height: calc(100vh - 50px);
    border-radius: 8px;
    overflow: hidden auto;

    @include tablet {
      right: -24px;
    }

    @include small-tablet {
      max-width: 480px;
    }

    @include phone {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $gray-10;

    .title {
      display: flex;
      gap: 4px;
      align-items: center;

      .count {
        padding: 2px 4px;
        border-radius: 8px;
        background-color: $red;
        color: $white;
        height: 14px;
        min-width: 14px;
        text-align: center;

        @include font-sm(700);

        line-height: 100%;
      }
    }
  }

  .empty {
    padding: 40px 16px;
    border-bottom: 1px solid $gray-10;
    color: $gray-80;
  }

  .footer {
    padding: 20px 16px;
    display: flex;
    gap: 6px;
    align-items: center;

    .icon {
      width: 14px;
      height: 14px;
      fill: $orange-yellow;
    }

    .link {
      color: $orange-yellow;
      text-decoration: none;
      text-align: right;

      @include font-lg(700);
    }
  }

  .card {
    position: relative;
    padding: 14px 20px 18px;
    border-bottom: 1px solid $gray-10;
  }

  .button {
    margin-left: -12px;
    background-color: transparent;
    border: none;
  }

  .unread {
    color: $gray-80;
    margin-left: 10px;
    margin-right: auto;

    @include phone {
      @include font-lg(400);
    }
  }
}
