@import "shared/assets/styles";

.container {
  padding-bottom: 100px;
}

.with-background {
  background-color: $white;
  padding: 15px;
  padding-bottom: 100px;
  margin-top: 120px;

  @include small-tablet {
    margin-top: 90px;
  }

  @include phone {
    margin-top: 60px;
  }
}
