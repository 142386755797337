$color-main-light: #fff;
$color-main-dark: #000;
$color-bg-header: #070707;
$color-bg-footer: #181818;
$color-bg-dark: #171717;
$color-bg-dark2: #333;
$color-bg-dark3: #313131;
$color-bg-light: #f9f9f9;
$color-text-gray: #bdbdbd;
$color-text-gray2: #828282;
$color-text-gray3: #4f4f4f;
$color-text-dark: #202020;
$color-text-light: #f2f2f2;
$color-border-gray: #e0e0e0;
$color-main-accent: #ffb600;
$color-main-accent-light: #977725;
$color-main-accent-dark: #e5a400;
$color-accent-red: #eb5757;
$color-accent-red-dark: #ff3a3a;
$color-accent-green: #27ae60;
$color-accent-purple: #9b51e0;
$color-accent-blue: #2f80ed;
$color-accent-blue-light: #63a0f2;
$color-accent-blue-dark: #2360b2;
$color-accent-yellow: #f2c94c;
$color-accent-yellow-light: rgba($color-accent-yellow, 0.1);
$color-accent-orange: #f2994a;
$orange-yellow: #ffb600;
$orange-yellow-hover: #ffc533;
$orange-yellow-active: #f4af02;
$orange-yellow-disabled: #fff0cc;
$orange-yellow-10: #fff8e6;
$orange: #ff8a00;
$orange-10: #fff3e6;
$black: #242424;
$black-active: #000;
$gray-80: #505050;
$gray-60: #7c7c7c;
$gray-40: #a7a7a7;
$gray-30: #bdbdbd;
$gray-20: #d3d3d3;
$gray-10: #e9e9e9;
$gray-5: #f4f4f4;
$gray-background: #f9f9f9;
$white: #fff;
$red: #ff3a3a;
$red-10: #ffebeb;
$green: #3bd520;
$green-disabled: #c4f2bc;
$green-10: #ebfbe9;
$yellow: #ffdc22;
$yellow-10: #fffbe9;
$blue: #00b2ff;
$blue-active: #00a0e6;
$blue-hover: #33c2ff;
$blue-disabled: #ccf0ff;
$blue-10: #e6f7ff;
$dark-blue: #06f;
$dark-blue-10: #e6f0ff;
$purple: #a500f2;
$purple-10: #f6e6fe;
$pink: #ff4ced;
$pink-10: #ffedfd;
